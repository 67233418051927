
.App {
  /*text-align: center;*/
  font-family: HiraMaruPro-W4, 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hg-button {
  min-width: 60px;
  min-height: 60px;
  font-size: 1.5em;
}

.hg-button-shift {
  max-width: 120px;
}
.hg-button-bksp {
  max-width: 180px;
}
.hg-button-kana,
.hg-button-alpha {
  max-width: 180px;
}


.hg-theme-default .hg-row:not(:last-child) {
  margin-bottom: 1px;
}

.hg-theme-default .hg-row .hg-button:not(:last-child) {
  margin-right: 1px;
}

.hg-button[data-skbtn="{}"] {
  visibility: hidden;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"] {
  max-width: unset;
}

.hg-button[data-skbtnuid="kana-r5b0"] {
  max-width: 120px;
}
