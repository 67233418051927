.country_class {
    background-color: #fff;
	-webkit-box-sizing: border-box;
			box-sizing: border-box;
	/* margin: 0 auto; */
	/* overflow: auto; */
    height: 6vh;
		/* width: 151.59px; */

}
.country_class:before, .country_class:after {
	-webkit-box-sizing: inherit;
	        box-sizing: inherit;
}
.country_class .box {
    background-color: #fff;
    border:1px solid #ccc;
    color:#245C72;
    margin-top:1.5vh;
}
.country_class input[type=radio] {
	display: none;
}
.country_class label:focus,
.country_class label:active{
	color: #777;
}
.country_class input:checked + label {
    background-color:#245C72;
    color: #fff;
}
.country_class label:focus:before,
.country_class label:active:before,
.country_class input:checked + label:before {
	border-color: #333;
	background: #fff;
}
.country_class label {
    color: #333;
	font-size: 1em;
	/* font-weight: bold; */ 
	line-height: 1;
	position: relative;
	display: block;
	overflow: hidden;
		/* padding: 2.2vh 0 2vh 4vw; */
		padding: 1.8vh;
	cursor: pointer;
	-webkit-transition: all 0.15s ease;
	        transition: all 0.15s ease;
	white-space: nowrap;
	text-overflow: ellipsis;
    background: #fff;
		margin: 0;
		height: 6vh;
}
.country_class label:before {
	position: absolute;
	top: 2.3vh;
	left: 1.5vw;
	width: 14px;
    height: 14px;
	content: '';
    border: 2px solid #333;
		border-radius: 1vw;
}
.country_class input:checked + label:before {
	/* border:2px solid #fff; 
	background: #245C72; */
	box-shadow: 0 0 0 3px #ffffff;
	background-color: #ffffff;
	border: solid 2px #245C72;
}