.cp_ipcheck {
	background-color: #fff;
-webkit-box-sizing: border-box;
		box-sizing: border-box;
margin: 0 auto;
overflow: auto;
  /* height: 25vh; */
  border: 1px solid #ccc;
  padding: 5px;
  margin: 0px 0px 10px 0px;
}
.cp_ipcheck:before, .cp_ipcheck:after {
-webkit-box-sizing: inherit;
				box-sizing: inherit;
}
.cp_ipcheck .box {
	background-color: #fff;
	border:1px solid #ccc;
	color:#245C72;
	margin-bottom:1.5vh;
}
.cp_ipcheck input[type=checkbox] {
display: none;
}
.cp_ipcheck label:focus,
.cp_ipcheck label:active{
color:  #707070;
}
.cp_ipcheck input:checked + label {
	background-color:#245C72;
	color: #fff;
}
.cp_ipcheck label:focus:before,
.cp_ipcheck label:active:before,
.cp_ipcheck input:checked + label:before {
border-color:  #707070;
background: #fff;
}
.cp_ipcheck label {
	color: #707070;
position: relative;
/* display: block; */
display: flex;
/* overflow: hidden; */
	padding: 1.5vh 0 1.5vh 7vh;
cursor: pointer;
-webkit-transition: all 0.15s ease;
				transition: all 0.15s ease;
white-space: nowrap;
text-overflow: ellipsis;
	background: #fff;    
	margin: 0;
font-size: 1.5rem;
line-height: 1.5rem;
height:4.5vw;
align-items: center
}
.cp_ipcheck label:before {
margin-left: 1vw;
content: '';
	border: 1px solid #707070;
	border-radius: 0.1vw;
content: '';
left: 1.5vw;
width: 2vw; /* ボックスの横幅 */
height: 2vw; /* ボックスの縦幅 */
display: inline-block;
position: absolute;
left: 0;
background-color:   #fff;
}
.cp_ipcheck input:checked + label:before {
border:2px solid #fff;
background: #245C72;
content: '\2713'; /* チェックの文字 */ 
font-size: 1.1em; /* チェックのサイズ */
line-height: 1em;
color: #707070; /* チェックの色 */
background-color: white; /* チェックした時の色 */
}

/* 6件以上の予約があるときスクロールバーを表示 */
 div.cp_ipcheck {
	margin-top:7.5vh;
	height:43vh;
  background-color: #ffffff;
	overflow-y:scroll;
  padding:10px;
}

 div.cp_ipcheck::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

div.cp_ipcheck::-webkit-scrollbar-track {
  height: 10px;
}

div.cp_ipcheck::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 10px;
  background: #ccc;
  box-shadow: inset 0 0 4px #707070;
}
